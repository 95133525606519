.event {
  max-width: 50rem;
  margin: 2rem auto;
  text-align: center;
}

.event img {
  width: 30rem;
  border-radius: 4px;
}

.actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.actions a,
.actions button {
  padding: 0.25rem 1rem;
  text-decoration: none;
  font: inherit;
  cursor: pointer;
}

.actions button {
  background-color: transparent;
  border: none;
  color: var(--color-primary-800);
}
